import * as url from "./urls";

const api = null;

const getProfileDetails = () => {
  return null;
};

const getSettings = () => {
  return null;
};
const updateSettings = (field: string, value: any) => {
  return null;
};

export { getProfileDetails, getSettings, updateSettings };
