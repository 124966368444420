import "./assets/scss/theme.scss";
import Routes from "./routes";

const App = () => {
  document.title = "CallAnalog | Webphone";

  return <Routes />;
};

export default App;
